import React, { useEffect, useState } from "react";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import { useCreateOrganization } from "./hooks/useCreateOrganization";
import { useUpdateOrganization } from "./hooks/useUpdateOrganization";
import QalboxImageUploader from "../media/helperComponents/qalboxImageUploader";

const initialOrgObject = {
  name: "",
  logo: "",
  description: ""
};

export const OrganizationModal = ({ data, handleUpdates }) => {
  const [organization, setOrganization] = useState(initialOrgObject);
  const [createMediaAccessHook] = useCreateOrganization();
  const [updateMediaAccessHook] = useUpdateOrganization();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setOrganization((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSave = () => {
    createMediaAccessHook(organization, handleUpdates);
  };

  const handleOnUpdate = async () => {
    updateMediaAccessHook(organization, handleUpdates);
  };

  useEffect(() => {
    if (data) {
      setOrganization(data);
    }
  }, [data]);

  return (
    <div>
      <QalboxTextInput
        label="Name"
        name="name"
        value={organization.name}
        onChange={handleInputTextChange}
      />
      <label>Description</label>
      <textarea
        label="Description"
        name="description"
        className="form-control"
        value={organization.description}
        onChange={handleInputTextChange}
      />
      <label style={{marginTop:'10px'}}>Organization Logo</label>
      <QalboxImageUploader
        name={"logo"}
        value={organization.logo}
        handleInputTextChange={handleInputTextChange}
      />
      <br></br>
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};