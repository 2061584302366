import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useDeleteEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteEvent = async (id, callback) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axiosService.delete(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-event-service/v1/delete/${id}`
      );
      const json = response?.data;
      callback(true);
      return json.success; // Return success status from the response
    } catch (err) {
      console.error(err);
      setError(err.message || 'An error occurred while deleting the event');
      return false; // Return false if an error occurs
    } finally {
      setIsLoading(false);
    }
  };

  return [ deleteEvent, isLoading, error ];
};