import { QalBoxDisplayUtil } from "../../utils/qalboxModalUtil";
import { useProcessModeratorVersion } from "../hooks/useProcessModeratorVersion";
import VodMediaFileUploadModal from "../modals/mediaFileUploadModal";
import LowQualityVideoPlayer from "./lowQualityVideoPlayer";

export function TencentFileComponent({ vodMetadata, handleMediaUpload }) {
  const [processModeratorVersion, processingModeratorVersion] = useProcessModeratorVersion();

  const handleUpload = () => {
    QalBoxDisplayUtil.triggerAppModal(
      `Upload Media File`,
      <VodMediaFileUploadModal
        vodMetadata={vodMetadata}
        onUpload={handleMediaUpload}
      />
    );
  };

  const handleViewVideoClicked = () => {
    if (vodMetadata?.lowQualityUrl) {
      QalBoxDisplayUtil.triggerAppModal(
        `Moderator version`,
        <LowQualityVideoPlayer videoUrl={vodMetadata?.lowQualityUrl} />
      );
    } else {
      if (!vodMetadata.uuid) return;
      processModeratorVersion(vodMetadata?.uuid, handleMediaUpload);
    }
  };

  return (
    <div className="form-group">
      <label>File</label>
      <div className="qalbox-form-row">
        <input
          value={vodMetadata?.file?.fileId}
          type="text"
          disabled
          className="form-control"
          placeholder="Destination Path"
          style={{ marginBottom: "5px" }}
        />
        <input
          value={vodMetadata?.file?.taskId}
          type="text"
          className="form-control input-row-margin"
          disabled
          placeholder="Destination Path"
        />
        <input
          value={vodMetadata?.file?.status}
          type="text"
          disabled
          className="form-control input-row-margin"
          placeholder="Destination Path"
        />
        <button
          onClick={handleUpload}
          className="form-control btn-primary input-row-margin"
        >
          Upload
        </button>
      </div>
      <div className="qalbox-form-row"></div>
      <button
        onClick={handleViewVideoClicked}
        className="form-control btn-primary"
      >
        {vodMetadata?.lowQualityUrl ? 'View Video' : 'Process Moderator Version' }
      </button>
    </div>
  );
}
