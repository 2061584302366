import _ from "lodash";
import { lazy, useState, Suspense } from "react";
import { toast } from "react-toastify";
import { QalBoxDisplayUtil } from "../../utils/qalboxModalUtil";
import { useDeleteAudio } from "../hooks/useDeleteAudio";
const AppModal = lazy(() => import("../../../../widgets/modal"));
const VodAudioFileUploadModal = lazy(() =>
  import("../modals/vodAudioUploadModal")
);

export const TencentAudioComponent = ({ vodMetadata, handleAudioChange }) => {
  const [showAudioUploadModal, setShowAudioUploadModal] = useState(false);
  const [deleteAudio, deletingAudio] = useDeleteAudio();

  const handleAudioRemove = (id) => {
    if (!id || !vodMetadata.uuid) {
      alert('Not a valid audio ID');
      return;
    }

    QalBoxDisplayUtil.triggerConfirmAlert(
      `Are you sure, you want to delete the Audio ID ${id}`,
      <></>,
      () => deleteAudio(vodMetadata.uuid, id, handleAudioChange)
    );
  };

  const handleInputTextChange = () => {};

  const handleAudioFileUpload = (isSuccess, response) => {
    if (isSuccess) {
      toast.success(
        `Audio file uploding successful !!! ${JSON.stringify(
          response.AddedAudioSet
        )}`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      setShowAudioUploadModal(false);
    } else {
      toast.error("Audio file uploding failed !!! " + response, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    handleAudioChange();
  };

  return (
    <div>
      <div className="form-group">
        <label>Audio</label>
        {vodMetadata.audio &&
          vodMetadata.audio.map((audioItem, index) => {
            return (
              <div key={index}>
                <div className="qalbox-form-row">
                  <div
                    style={{ marginRight: "10px" }}
                    onClick={() => handleAudioRemove(audioItem.id)}
                  >
                    <span
                      className="glyphicon glyphicon-remove"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    value={audioItem.id}
                    name={`audio.id-${index}`}
                    type="text"
                    disabled
                    onChange={(e) => handleInputTextChange(e, index)}
                    className="form-control small-input-200"
                    placeholder="Audio ID"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={audioItem.language}
                    name={`audio.language-${index}`}
                    type="text"
                    disabled
                    onChange={(e) => handleInputTextChange(e, index)}
                    className="form-control small-input-50 input-row-margin"
                    placeholder="Audio Language"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={audioItem.name}
                    name={`audio.name-${index}`}
                    type="text"
                    disabled
                    onChange={(e) => handleInputTextChange(e, index)}
                    className="form-control small-input-100 input-row-margin"
                    placeholder="Audio Name"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={audioItem.format}
                    name={`audio.format-${index}`}
                    type="text"
                    disabled
                    className="form-control small-input-format input-row-margin"
                    placeholder="Format"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={audioItem.status}
                    type="text"
                    disabled
                    className="form-control small-input-status input-row-margin"
                    placeholder="Status"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={audioItem.taskId}
                    type="text"
                    disabled
                    className="form-control small-input-taskid input-row-margin"
                    placeholder="Task ID"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={audioItem.url}
                    type="text"
                    disabled
                    className="form-control input-row-margin"
                    placeholder="Task ID"
                    style={{ marginBottom: "5px" }}
                  />
                </div>
              </div>
            );
          })}
        <div onClick={() => setShowAudioUploadModal(true)}>
          <span className="glyphicon glyphicon-plus" aria-hidden="true" />
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <AppModal
          show={showAudioUploadModal}
          showBody={true}
          headingText={`Upload Audio for ${vodMetadata?.uuid} - ${vodMetadata?.title}`}
          modalBody={
            <VodAudioFileUploadModal
              metadata={vodMetadata}
              onUpload={handleAudioFileUpload}
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => {
            setShowAudioUploadModal(false);
          }}
        />
      </Suspense>
    </div>
  );
};
