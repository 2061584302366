import React, { useRef } from "react";

const LowQualityVideoPlayer = ({ videoUrl }) => {
  const videoRef = useRef(null);

  return (
    <div>
      <video 
        ref={videoRef} 
        controls
        src={videoUrl} 
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LowQualityVideoPlayer;
