import _ from "lodash";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import { TencentSubtitleComponent } from "../helperComponents/tencentSubtitleComponent";
import { useFetchVodMetadataById } from "../hooks/useFetchVodMetadataById";
import { TencentAudioComponent } from "../helperComponents/tencentAudioComponent";
import QalboxSelectDropdown from "../../media/helperComponents/qalboxSelect";
import { convertArrayToSelectList, convertToSelectObject } from "../../helper";
import { SONIC_BRANDING } from "../constants";
import { VodMetadataHelper } from "../helper";
import { useTranscodeMedia } from "../hooks/useTranscodeMedia";
import { useUpdateVodMetadata } from "../hooks/useUpdateVodMetadata";
import TencentTranscodeTaskComponent from "../helperComponents/tencentTranscodeTaskComponent";
import VodMediaFileUploadModal from "./mediaFileUploadModal";
import { QalBoxDisplayUtil } from "../../utils/qalboxModalUtil";
import { TencentFileComponent } from "../helperComponents/tencentFileComponent";
import { useSyncTranscodeUrls } from "../hooks/useSyncTranscodeUrls";
import { TencentSnapshotComponent } from "../helperComponents/tencentSnapshotComponent";

export default function VodMetadatadModal({
  uuid,
  title,
  handleTranscodeComplete,
}) {
  const [refresh, setRefresh] = useState(false);
  const [vodMetadata, setVodMetadata] = useState({
    uuid: uuid,
    title: title,
    file: {},
    audio: [],
    subtitles: [],
    branding: "",
    drm: true,
    mediaFileUrl: "",
    orientation: "landscape",
    urls: {
      hls: null,
      dash: null,
    },
  });
  const [vodMetadataResponse, isVodMetadataResponseLoading] =
    useFetchVodMetadataById(uuid, refresh);

  const [transcodeMedia, isTranscoding] = useTranscodeMedia();
  const [updateVodMetadata, isUpdating] = useUpdateVodMetadata();
  const [syncTranscodeUrls, isSyncing] = useSyncTranscodeUrls();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setVodMetadata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelect = (name, option) => {
    setVodMetadata((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setVodMetadata((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubtitleChange = () => {
    setRefresh((prev) => !prev);
  };

  const handleTranscodeMedia = () => {
    if (!uuid || !vodMetadata.file.fileId) {
      alert("Missing details ");
      return;
    }

    transcodeMedia(uuid, handleTranscodeComplete);
  };

  const handleTranscodeMediaButtonClicked = () => {
    VodMetadataHelper.triggerConfirmAlert(
      "Trancode Media",
      "Do you want to transcode this Media",
      handleTranscodeMedia
    );
  };

  const handleSaveButtonClick = () => {
    if (!uuid) {
      return;
    }

    updateVodMetadata(uuid, vodMetadata, handleSubtitleChange);
  };

  const handleSendDataToStaging = () => {
    if (!uuid) {
      return;
    }

    syncTranscodeUrls(uuid, handleSubtitleChange);
  }

  const handleViewTranscodeClicked = () => {
    QalBoxDisplayUtil.triggerConfirmAlert(
      `Tasks for Media - ${vodMetadata.uuid} - ${vodMetadata.title}`,
      <TencentTranscodeTaskComponent tasks={vodMetadata?.transcode} />,
      () => alert("done")
    );
  };

  useEffect(() => {
    if (vodMetadataResponse) {
      setVodMetadata(vodMetadataResponse);
    }
  }, [vodMetadataResponse]);

  return (
    <LoadingOverlay
      active={
        isVodMetadataResponseLoading || isTranscoding || isUpdating || isSyncing
      }
      spinner={<ScaleLoader />}
      text="Loading..."
    >
      <div className="form-group">
        <label>Title</label>
        <input
          value={vodMetadata.title}
          name="title"
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder="Destination Path"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <div className="qalbox-form-row">
        <div className="form-group">
          <label>DRM</label>
          <input
            type="checkbox"
            className="form-control"
            name="drm"
            checked={vodMetadata.drm}
            onChange={handleCheckboxChange}
          />
        </div>
        <div className="form-group small-input-200 margin-left-5">
          <label>Branding</label>
          <QalboxSelectDropdown
            name={"branding"}
            selectedOption={convertToSelectObject(vodMetadata.branding)}
            options={convertArrayToSelectList(SONIC_BRANDING)}
            onChange={handleSelect}
          />
        </div>
        <div className="form-group small-input-200 margin-left-5">
          <label>Orientation</label>
          <QalboxSelectDropdown
            name={"orientation"}
            selectedOption={convertToSelectObject(vodMetadata.orientation)}
            options={convertArrayToSelectList(["portrait", "landscape"])}
            onChange={handleSelect}
          />
        </div>
        <div className="form-group margin-left-5">
          <label>Media File URL</label>
          <input
            value={vodMetadata.mediaFileUrl}
            name="mediaFileUrl"
            type="text"
            onChange={handleInputTextChange}
            className="form-control small-input-full"
          />
        </div>
      </div>
      <TencentFileComponent
        vodMetadata={vodMetadata}
        handleMediaUpload={handleSubtitleChange}
      />
      <TencentSnapshotComponent
        vodMetadata={vodMetadata}
        handleInputTextChange={handleInputTextChange}
        onScreenshot={handleSubtitleChange}
      />
      <TencentSubtitleComponent
        vodMetadata={vodMetadata}
        handleSubtitleChange={handleSubtitleChange}
      />
      <TencentAudioComponent
        vodMetadata={vodMetadata}
        handleAudioChange={handleSubtitleChange}
      />
      <div className="form-group">
        <button
          onClick={handleViewTranscodeClicked}
          className="form-control btn-info"
        >
          View Transcode Tasks
        </button>
      </div>
      <div className="qalbox-form-row">
        <div className="form-group small-input-full">
          <label>DASH URL</label>
          <input
            value={vodMetadata.urls?.dash?.url}
            name="title"
            type="text"
            onChange={handleInputTextChange}
            className="form-control"
            placeholder="Destination Path"
            style={{ marginBottom: "5px" }}
          />
          <label>HLS URL</label>
          <input
            value={vodMetadata.urls?.hls?.url}
            name="title"
            type="text"
            onChange={handleInputTextChange}
            className="form-control"
            placeholder="Destination Path"
            style={{ marginBottom: "5px" }}
          />
        </div>
        <button
          onClick={handleSendDataToStaging}
          className="form-control btn-success margin-left-5 small-input-50"
        >
          <span className="glyphicon glyphicon-send"></span>
        </button>
      </div>
      <div className="qalbox-form-row">
        <button
          onClick={handleSaveButtonClick}
          className="form-control btn-primary"
        >
          Save VOD Metadata
        </button>
        <button
          onClick={handleTranscodeMediaButtonClicked}
          className="form-control btn-success margin-left-5"
        >
          Transcode
        </button>
      </div>
    </LoadingOverlay>
  );
}
