import _ from "lodash";
import React, { useEffect, useState } from "react";
import QalboxSelectDropdown from "../../media/helperComponents/qalboxSelect";
import { convertArrayToSelectList, convertToSelectObject, getCountryCodesAsLabelValuePair, getMediaUrlObjectByDestination, getPreviewUrlObjectByDestination, getShortsUrlObjectByDestination } from "../../helper";
import {
  ALLOWED_MATURITY_RATINGS,
  ALLOWED_MAX_QUALITIES,
  PLAY_RESTRICTIONS,
  SHORTS_CDN_LOCATION,
} from "../../Constants";
import QalboxMultiSelectDropdown from "../../media/helperComponents/qalboxMultiSelect";
import QalboxURLInput from "../../media/helperComponents/qalboxURLInput";
import QalboxMediaLabelBox from "../../media/helperComponents/qalboxMediaLabelBox";
import AppModal from "../../../../widgets/modal";
import EncodeShort from "./encodeShort";
import ShortMediaImageBox from "../helperComponents/shortMediaImageBox";
import { QalboxTagsDropdown } from "../../displayCategories/helperComponents/QalboxTagsDropdown";
import { QalboxGenresDropdown } from "../../displayCategories/helperComponents/QalboxGenresDropdown";
import { useCreateShort } from "../hooks/useCreateShort";
import { useUpdateShort } from "../hooks/useUpdateShort";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import QalboxURLInputV1 from "../../media/helperComponents/qalboxURLInputV1";
import VodMetadatadModal from "../../vodMetadata/modals/vodMetadataModal";

const FIRST_FRAME_IMAGE_PREFIX = "/firstFrame.0000001.jpg";

export const CreateShortModal = ({ data, handleUpdates }) => {
  const [isDrmProtected, setDrmProtected] = useState(false);
  const [shortMedia, setShortMedia] = useState({
    title: "",
    maturity_rating: "",
    max_quality: "",
    released_year: "2023",
    tags: [],
    genres: [],
    urls: [],
    duration: "",
    media_labels: [],
    thumbnail_image: {},
    media_uuid: "",
    cast: [],
    play_restrictions: [],
    directors: [],
    has_subtitles: false,
    has_details: false,
    description: "",
    uuid: "",
    is_active: true,
    countries_allowed: [],
    countries_disallowed: [],
    first_frame: '',
    is_featured: false,
    order_number: '',
  });
  const [showEncodeDetailsModal, setShowEncodeDetailsModal] = useState(false);
  const [createShort, isCreateLoading] = useCreateShort();
  const [updateShort, isUpdateLoading] = useUpdateShort();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;
    const arrayNames = [
      "genres",
      "tags",
      "cast",
      "countries_allowed",
      "countries_disallowed",
      "directors",
      "play_restrictions",
    ];

    if (arrayNames.includes(name)) {
      value = value.split(",").map((item) => item);
    }

    setShortMedia((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputImageChange = (e) => {
    let { name, value } = e.target;

    const nameArr = name.split(".");

    if (nameArr && nameArr.length > 1) {
      const prefix = nameArr[0];
      const suffix = nameArr[1];

      let newImageObject = {};

      const prevImageObject = _.get(shortMedia, prefix, null);

      if (shortMedia && prevImageObject) {
        newImageObject = { ...prevImageObject, [suffix]: value };
      } else {
        newImageObject = { [suffix]: value };
      }

      setShortMedia((prevState) => ({
        ...prevState,
        [prefix]: newImageObject,
      }));
    } else {
      setShortMedia((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelect = (name, option) => {
    setShortMedia((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleMultiSelect = (name, array) => {
    const value = array.map((x) => x.value);

    setShortMedia((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMediaLabelUpdate = (mediaLabelList) => {
    setShortMedia((prevState) => ({
      ...prevState,
      media_labels: mediaLabelList,
    }));
  };

  const handleURLChange = (name, urlArray) => {
    setShortMedia((prevState) => ({
      ...prevState,
      [name]: urlArray,
    }));
  };

  const checkInput = (shortMedia) => {
    if (shortMedia && (!isNaN(shortMedia.order_number) || shortMedia.order_number === '')) {
      return true;
    } else {
      alert('Order Number supports Empty or Numeric values !!!');
      return false;
    }
  }

  const handleOnSave = async () => {
    if (!checkInput(shortMedia)) return;
    createShort(shortMedia, handleUpdates);
  };

  const handleOnUpdate = async () => {
    if (!checkInput(shortMedia)) return;
    updateShort(shortMedia, handleUpdates);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setShortMedia((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const onEncodeDetailsViewClicked = () => {
    setShowEncodeDetailsModal(true);
  };

  const onEncodeComplete = (destinationPath) => {
    const firstFrameImageUrl = SHORTS_CDN_LOCATION + destinationPath + FIRST_FRAME_IMAGE_PREFIX;
    setShortMedia((prevState) => ({
      ...prevState,
      urls: isDrmProtected ? getMediaUrlObjectByDestination(destinationPath, true) : getPreviewUrlObjectByDestination(destinationPath, true),
      first_frame: firstFrameImageUrl,
      thumbnail_image: {
        large:
          shortMedia.thumbnail_image && shortMedia.thumbnail_image.large
            ? shortMedia.thumbnail_image.large
            : firstFrameImageUrl,
        small:
          shortMedia.thumbnail_image && shortMedia.thumbnail_image.small
            ? shortMedia.thumbnail_image.small
            : firstFrameImageUrl,
      },
    }));
  };

  const onMediaFileUpload= (isSuccess, error) => {
    if (isSuccess) {
      alert('upload success !!!');
    } else {
      alert(`upload error !!! ${error.message}`);
    }
  };

  useEffect(() => {
    if (data) {
      setShortMedia(data);
      const urlArray = data.urls;
      if (urlArray && urlArray.length > 0) {
        setDrmProtected(urlArray[0]?.configs !== null && urlArray[0]?.configs !== undefined);
      }
    }
  }, [data]);

  return (
    <LoadingOverlay
      active={isCreateLoading || isUpdateLoading}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      {data && process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE && (
        <button
          className="pagination-button"
          style={{ marginBottom: "10px" }}
          onClick={onEncodeDetailsViewClicked}
        >
          Transcode Video
        </button>
      )}
      <div className="form-group">
        <label>Title</label>
        <input
          name="title"
          value={shortMedia.title}
          onChange={handleInputTextChange}
          type="text"
          className="form-control"
          placeholder="Title"
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <textarea
          value={shortMedia.description}
          name={"description"}
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder={"Description"}
          style={{ marginBottom: "5px", marginRight: "10px" }}
        />
      </div>
      <div style={{ display: "inline-flex" }}>
        <div
          className="form-group"
          style={{ marginRight: "100px", width: "200px" }}
        >
          <label>Maturity Rating</label>
          <QalboxSelectDropdown
            name={"maturity_rating"}
            selectedOption={convertToSelectObject(shortMedia.maturity_rating)}
            options={convertArrayToSelectList(ALLOWED_MATURITY_RATINGS)}
            onChange={handleSelect}
          />
        </div>
        <div
          className="form-group"
          style={{ marginRight: "100px", width: "200px" }}
        >
          <label>Max video Quality</label>
          <QalboxSelectDropdown
            name={"max_quality"}
            selectedOption={convertToSelectObject(shortMedia.max_quality)}
            options={convertArrayToSelectList(ALLOWED_MAX_QUALITIES)}
            onChange={handleSelect}
          />
        </div>
        <div className="form-group" style={{ width: "300px" }}>
          <label>Play Restrictions</label>
          <QalboxMultiSelectDropdown
            name={"play_restrictions"}
            onChange={handleMultiSelect}
            selectedOptions={convertArrayToSelectList(
              shortMedia.play_restrictions
            )}
            options={convertArrayToSelectList(PLAY_RESTRICTIONS)}
          />
        </div>
      </div>
      <QalboxTagsDropdown
        tags={shortMedia.tags}
        handleMultiSelect={handleMultiSelect}
      />
      <div style={{ display: "inline-flex" }}>
        <div className="form-group" style={{ marginRight: "20px" }}>
          <label>Main Media UUID</label>
          <input
            name="media_uuid"
            value={shortMedia.media_uuid}
            onChange={handleInputTextChange}
            type="text"
            className="form-control"
            placeholder="Main Media UUID"
          />
        </div>
        <div className="form-group" style={{ marginRight: "20px" }}>
          <label>Released Year</label>
          <input
            name="released_year"
            value={shortMedia.released_year}
            onChange={handleInputTextChange}
            type="text"
            className="form-control"
            placeholder="Released Year"
          />
        </div>
        <div className="form-group" style={{ marginRight: "20px" }}>
          <label>Duration</label>
          <input
            name="duration"
            value={shortMedia.duration}
            onChange={handleInputTextChange}
            type="text"
            className="form-control"
            placeholder="Duration"
          />
        </div>
        <div className="form-group" style={{ marginRight: "20px" }}>
          <label>Order Number</label>
          <input
            name="order_number"
            value={shortMedia.order_number}
            onChange={handleInputTextChange}
            type="text"
            className="form-control"
            placeholder="Order Number"
          />
        </div>
      </div>
      <QalboxGenresDropdown
        genres={shortMedia.genres}
        handleMultiSelect={handleMultiSelect}
      />
      <QalboxURLInputV1
        componentName={"urls"}
        urlArray={shortMedia.urls ? shortMedia.urls : []}
        onChange={handleURLChange}
        isDrmProtected={isDrmProtected}
        setDrmProtected={setDrmProtected}
      />

      <QalboxMediaLabelBox
        mediaLabelList={
          shortMedia && shortMedia.media_labels ? shortMedia.media_labels : []
        }
        handleMediaLabelUpdate={handleMediaLabelUpdate}
        isFreeMedia={false}
      />
      <div style={{ display: "inline-flex" }}>
        <div className="form-group" style={{ marginRight: "10px" }}>
          <label>Is Active</label>
          <input
            type="checkbox"
            name="is_active"
            checked={shortMedia.is_active}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        </div>
        <div className="form-group" style={{ marginRight: "10px" }}>
          <label>Has Subtitles</label>
          <input
            type="checkbox"
            name="has_subtitles"
            checked={shortMedia.has_subtitles}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        </div>
        <div className="form-group" style={{ marginRight: "10px" }}>
          <label>Has Details</label>
          <input
            type="checkbox"
            name="has_details"
            checked={shortMedia.has_details}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        </div>
        <div className="form-group" style={{ marginRight: "10px" }}>
          <label>Is Featured</label>
          <input
            type="checkbox"
            name="is_featured"
            checked={shortMedia.is_featured}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        </div>
      </div>
      <div className="form-group">
        <label>Cast</label>
        <input
          name="cast"
          value={shortMedia.cast}
          onChange={handleInputTextChange}
          type="text"
          className="form-control"
          placeholder="Cast"
        />
      </div>
      <div className="form-group">
        <label>Directors</label>
        <input
          name="directors"
          value={shortMedia.directors}
          onChange={handleInputTextChange}
          type="text"
          className="form-control"
          placeholder="Directors"
        />
      </div>
      <div className="form-group">
        <label>Countries Allowed</label>
        <QalboxMultiSelectDropdown
          name={"countries_allowed"}
          onChange={handleMultiSelect}
          selectedOptions={convertArrayToSelectList(
            shortMedia.countries_allowed
          )}
          options={getCountryCodesAsLabelValuePair()}
        />
      </div>
      <div className="form-group">
        <label>Countries Disallowed</label>
        <QalboxMultiSelectDropdown
          name={"countries_disallowed"}
          onChange={handleMultiSelect}
          selectedOptions={convertArrayToSelectList(
            shortMedia.countries_disallowed
          )}
          options={getCountryCodesAsLabelValuePair()}
        />
      </div>
      <ShortMediaImageBox
        thumbnail_image={shortMedia.thumbnail_image}
        first_frame={shortMedia.first_frame}
        handleInputTextChange={handleInputImageChange}
      />
      <div className="form-group" style={{ marginTop: "5px" }}>
        {data ? (
          <button className="pagination-button" onClick={handleOnUpdate}>
            Update
          </button>
        ) : (
          <button className="pagination-button" onClick={handleOnSave}>
            Save
          </button>
        )}
      </div>
      <AppModal
        show={showEncodeDetailsModal}
        showBody={true}
        headingText={`Transcode Short - ${shortMedia.uuid}`}
        modalBody={
          <VodMetadatadModal
            uuid={shortMedia.uuid ? `shorts_${shortMedia.uuid}` : null}
            title={shortMedia.title}
            handleTranscodeComplete={onEncodeComplete}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {
          setShowEncodeDetailsModal(false);
        }}
      />
    </LoadingOverlay>
  );
};
