import React, { useEffect, useState } from "react";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import { useCreateEvent } from "./hooks/useCreateEvent";
import { useUpdateEvent } from "./hooks/useUpdateEvent";
import { QalboxDateTimeInput } from "../media/helperComponents/qalboxDateTimeInput";
import { convertArrayToSelectListOrg, convertToSelectObject, getDateTime } from "../helper";
import QalboxSelectDropdown from "../media/helperComponents/qalboxSelect";
import QalboxMultiSelectDropdown from "../media/helperComponents/qalboxMultiSelect";

const initialOrgObject = {
  title: "",
  description: "",
  organization_id: "",
  event_start_timestamp: "",
  panelist_card_title: "",
  panelists: []
};

export const EventModal = ({ data, panelists, organizations, handleUpdates }) => {
  const [event, setEvent] = useState(initialOrgObject);
  const [selectedPanelist, setSelectedPanelist] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState();
  const [createEventHook] = useCreateEvent();
  const [updateEventHook] = useUpdateEvent();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSave = () => {
    createEventHook(event, handleUpdates);
  };

  const handleOnUpdate = async () => {
    updateEventHook(event, handleUpdates);
  };

  const handleSelect = (name, option) => {
    setEvent((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
    setSelectedOrg(option)
  };

  const handleMultiSelect = (name, array) => {
    const value = array.map((x) => x.value);

    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setSelectedPanelist(array);
  };

  useEffect(() => {
    if (data) {
      setEvent(data);
    }
  }, [data]);

  useEffect(() => {
    if(data && panelists && panelists.length > 0){
      const filtered = panelists.filter(panelist => data.panelists?.includes(panelist.id))
      if(filtered.length){
        setSelectedPanelist(convertArrayToSelectListOrg(filtered))
      }
    }

    if(data && organizations && organizations.length > 0){
      const filteredOrg = organizations.find(organizations => data.organization_id === organizations.id)
      if(filteredOrg){
        setSelectedOrg({value:filteredOrg.id, label:filteredOrg.name})
      }
    }
  },[panelists, organizations])

  return (
    <div>
      <QalboxTextInput
        label="Title"
        name="title"
        value={event.title}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Panelist Section Title"
        name="panelist_card_title"
        value={event.panelist_card_title}
        onChange={handleInputTextChange}
      />
      <QalboxDateTimeInput
        name={"event_start_timestamp"}
        label={"Event Start Timestamp"}
        value={getDateTime(event.event_start_timestamp)}
        onChange={handleInputTextChange}
      />
      <label>Select Organization</label>
      <QalboxSelectDropdown
          name={"organization_id"}
          selectedOption={selectedOrg}
          options={convertArrayToSelectListOrg(organizations)}
          onChange={handleSelect}
      />
      <label style={{marginTop:'10px'}}>Select Panelists</label>
      <QalboxMultiSelectDropdown
        name={"panelists"}
        onChange={handleMultiSelect}
        selectedOptions={selectedPanelist}
        options={convertArrayToSelectListOrg(panelists)}
      />
      <label style={{marginTop:'10px'}}>Description</label>
      <textarea
        label="Description"
        name="description"
        value={event.description}
        onChange={handleInputTextChange}
        className="form-control"
      />
      <br></br>
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};