import React,
{
  Component,
  lazy,
  Suspense,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import DeveloperOptions from './pages/developeroptions/index';
import General from './pages/general';
import Login from './pages/login/index';
import Logout from './pages/logout/index';
import Moderators from './pages/moderators/index';
import PrayerRequests from './pages/prayerrequests/index';

// Users section
import SearchUser from './pages/searchuser/index';
import TransferPremium from './pages/transferpremium/index';
import SubscriptionHistory from './pages/subscriptionhistory/index';
import DeleteUserLogs from './pages/searchuser/deleteUserLogs';
// import Polls from './pages/polls/index';

// Promo Code section
import OnetimePromoCodes from './pages/promocodes/oneTimeCodes';
import OnetimePromoCodesLegacy from './pages/promocodes/oneTimeCodesLegacy';
import ListGiftAPremiums from './pages/promocodes/listGiftAPremiums';
import MultiUsagePromoCodes from './pages/promocodes/multiUsageCodes';
import GeneratePromoCodes from './pages/promocodes/generate';
import SearchPromoCode from './pages/promocodes/searchPromoCode';

import PrayerTimes from './pages/prayertimes/index';
// import ContentCreate from './pages/content/create';
// import ContentList from './pages/content/list';

// Content V2
import ContentCreateV2 from './pages/content-v2/create';
import ContentEditV2 from './pages/content-v2/edit';
import ContentListV2 from './pages/content-v2/list';
import UploadContentsImages from './pages/content-v2/uploadContentsImages';
import UploadContentsExcel from './pages/content-v2/uplodContentsExcel';
import ListSpecialContents from './pages/content-v2/listSpecialContents';
import ListRamadanContents from './pages/content-v2/listRamadanContents';
import ListContentsV2HashTags from './pages/content-v2/listHashTags';
import ContentsV2TrendingHashTags from './pages/content-v2/trendingHashTags';

// Halal Places section
// import PendingFiles from './pages/halalplaces/pendingFiles';
// import PendingPlaces from './pages/halalplaces/pendingPlaces';
// import HalalStaffStats from './pages/halalplaces/staffStats';
// import ApprovedFiles from './pages/halalplaces/approvedFiles';
// import PendingClaims from './pages/halalplaces/pendingClaims';
// import ApprovedClaims from './pages/halalplaces/approvedClaims';
// import HalalUserReports from './pages/halalplaces/reports';
// import ApprovedPlaces from './pages/halalplaces/approvedPlaces';
// import HalalPlaceStats from './pages/halalplaces/placeStats';

// import Ecommerce from './pages/ecommerce/index';

// Aideen
import ClearQueryCollection from './pages/aideen';

// Redis
import RedisInfoCFs from './pages/redis/infoCfs';
import RedisInfoAPIV2 from './pages/redis/infoApiV2';
import RedisFlushCache from './pages/redis/flushCache';

// Hajj and Umrah Pages
import HajjAndUmrahCreate from './pages/hajjandumrah/create';
import HajjAndUmrahList from './pages/hajjandumrah/list';
import HajjAndUmrahUploadImages from './pages/hajjandumrah/uploadImages';
import HajjAndUmrahUploadExcel from './pages/hajjandumrah/uploadExcel';

// Precalc
import Precalc from './pages/precalc/precalc';
import UpdatePrecalcsList from './pages/precalc/update-precalcs-list';
import TestPrecalcApi from './pages/precalc/testApi';
import ListPrecalcFileVersions from './pages/precalc/listPrecalcFileVersions';
import ClearPrecalcCache from './pages/precalc/clearPrecalcCache';

// Ad Units
// import ListAdUnits from './pages/adunits/list';
// import CreateAdUnit from './pages/adunits/create';

// Timeline Promos
import ListTimelinePromos from './pages/timelinepromo/list';
import CreateTimelinePromo from './pages/timelinepromo/create';
import TimelinePromoUploadImages from './pages/timelinepromo/uploadImages'
import TimelinePromoUploadExcel from './pages/timelinepromo/uploadExcel'

//Reports
import Reports from './pages/reports/reports';

// Settings
import UpdateSettingTimestamps from './pages/settings/updateTimestamps';
import UpdateRawSettingsFile from './pages/settings/updateRawSettingsFile';
import UpdateSettingsFileKeyValues from './pages/settings/updateKeyValues';
import ListSettingsFileVersions from './pages/settings/listSettingsFileVersions';
import TestSettingAPI from './pages/settings/testSettingsApi';

// Quran Playlists
import QuranPlaylistFeaturedList from './pages/quranplaylists/featuredList';
import QuranPlaylistBulkUploadFeatured from './pages/quranplaylists/bulkUploadFeatured';

// Hijri
import Hijri from './pages/hijri/hirji';
import HijriYears from './pages/hijri/hijriYears';
import EidUlFitrSightings from './pages/hijri/eidUlFitrSightings';
import RamdanSightings from './pages/hijri/ramadanSightings';
import ListHijriFileVersions from './pages/hijri/listFileVersions';

// Email Dashboard
import EmailDashboard from './pages/emaildashboard';
import MGEmailTags from './pages/emaildashboard/tags';

// Quran Quotes
import QuranQuotesList from './pages/quran-quotes/list';
import QuranQuotesUploadExcel from './pages/quran-quotes/uploadExcel'

// Virtual Tours
import VirtulaToursUploadImages from './pages/virtual-tours/uploadImages';
import VirtulaToursUploadExcel from './pages/virtual-tours/uploadExcel';

// Subscription dashboard
import PremiumSKUManager from './pages/subscriptiondashboard/premiumSKUManager';
import SubscriptionStats from './pages/subscriptiondashboard/subscriptionStats';

// QalBox
import QalBoxUploadMediaExcel from './pages/qalbox/uploadMediaExcel';
import QalBoxUploadDisplayCategoriesExcel from './pages/qalbox/uploadDisplayCategoriesExcel';
import QalBoxUploadImages from './pages/qalbox/uploadImages';
import QalBoxSearchMedia from './pages/qalbox/searchMedia';
import QalBoxSearchMediaV2 from './pages/qalbox/media/searchMediaV2';
import QalBoxMediaTags from './pages/qalbox/mediaTags';
import QalBoxGenres from './pages/qalbox/genres';
import QalboxStreamers from './pages/qalbox/streamers/streamers';

import QalBoxDisplayCategories from './pages/qalbox/displayCategories/displayCategories';
import QalboxMediaAccess from './pages/qalbox/access/access';
import QalboxOrganization from './pages/qalbox/organization/organization';
import QalboxPanelist from './pages/qalbox/panelist/panelist';
import QalboxEvents from './pages/qalbox/events/events';

import ToolsUploadImages from './pages/tools/uploadImages'

// import WIP from './pages/wip/index';
import firebase from 'firebase/app';

// Global CSS on App
import './App.css';
import firebaseEnvConfigs from './configs/firebase';
import {
  WEB_APP_ENV,
} from './Constants';

import UpdateContent from "./pages/dailydeen/updateContent";
import UpdateContentCSV from "./pages/dailydeen/updateContentCsv";
import { QalboxShorts } from './pages/qalbox/qalboxShorts/qalboxShorts';
import QalboxBrands from './pages/qalbox/brands/brands';
import QalboxAVOD from './pages/qalbox/avod/avod';
import LessonIngestionUploadCSV from './pages/content-v2/lessonIngestion';
import { QalboxVODMetadata } from './pages/qalbox/vodMetadata/vodMetadata';
const ChatPage = lazy(() => import('./pages/qalbox/chatModeration/ChatPage'));
const ChatTablePage = lazy(() => import('./pages/qalbox/chatModeration/ChatTablePage'))

class App extends Component {
  constructor(props) {
    super(props);
    FirebaseInit();
  }

  render() {
    return (
      <div>
        <Router>
          <div>
            <Route exact path="/" render={() => (<Redirect to="/login"/> )}/>
            <Route path="/developer-options" component={DeveloperOptions}/>
            <Route path="/moderators" component={Moderators}/>
            <Route path="/search-user/:query?" component={SearchUser}/>
            <Route path="/delete-user-logs" component={DeleteUserLogs}/>
            <Route path="/login" component={Login}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/prayer-requests" component={PrayerRequests}/>
            <Route path="/transfer-premium" component={TransferPremium}/>
            <Route path="/subscription-history" component={SubscriptionHistory}/>
            <Route path="/onetime-promo-codes/:query?" component={OnetimePromoCodes}/>
            <Route path="/onetime-promo-codes-legacy" component={OnetimePromoCodesLegacy}/>
            <Route path="/list-gift-a-premiums/:query?" component={ListGiftAPremiums}/>
            <Route path="/multiusage-promo-codes" component={MultiUsagePromoCodes}/>
            <Route path="/generate-promo-codes" component={GeneratePromoCodes}/>
            <Route path="/search-promo-code/:query?" component={SearchPromoCode}/>
            <Route path="/prayer-times" component={PrayerTimes}/>
            {/*<Route path="/content-create" component={ContentCreate}/>*/}
            {/*<Route path="/content-list" component={ContentList}/>*/}
            <Route path="/content-v2-create" component={ContentCreateV2}/>
            <Route path="/content-v2-edit/:query?" component={ContentEditV2}/>
            <Route path="/content-v2-list" component={ContentListV2}/>
            <Route path="/content-v2-list-hashtags" component={ListContentsV2HashTags}/>
            <Route path="/content-v2-images-upload" component={UploadContentsImages}/>
            <Route path="/content-v2-lesson-ingestion" component={LessonIngestionUploadCSV}/>
            <Route path="/content-v2-excel-upload" component={UploadContentsExcel}/>
            <Route path="/content-v2-list-ramadan-contents" component={ListRamadanContents}/>
            <Route path="/content-v2-list-special-contents" component={ListSpecialContents}/>
            <Route path="/content-v2-trending-hashtags" component={ContentsV2TrendingHashTags}/>
            {/*<Route path="/halal-pending-files" component={PendingFiles}/>*/}
            {/*<Route path="/halal-pending-places" component={PendingPlaces}/>*/}
            {/*<Route path="/halal-pending-claims" component={PendingClaims}/>*/}
            {/*<Route path="/halal-approved-files" component={ApprovedFiles}/>*/}
            {/*<Route path="/halal-approved-claims" component={ApprovedClaims}/>*/}
            {/*<Route path="/halal-approved-places" component={ApprovedPlaces}/>*/}
            {/*<Route path="/halal-user-reports" component={HalalUserReports}/>*/}
            {/*<Route path="/halal-staff-stats" component={HalalStaffStats}/>*/}
            {/*<Route path="/halal-place-stats" component={HalalPlaceStats}/>*/}
            {/*<Route path="/polls" component={Polls}/>*/}
            {/*<Route path="/ecommerce" component={Ecommerce}/>*/}
            <Route path="/redis-info-cfs" component={RedisInfoCFs}/>
            <Route path="/redis-info-api-v2" component={RedisInfoAPIV2}/>
            <Route path="/redis-flush-cache" component={RedisFlushCache}/>
            <Route path="/hajj-and-umrah-create" component={HajjAndUmrahCreate}/>
            <Route path="/hajj-and-umrah-edit" component={HajjAndUmrahCreate}/>
            <Route path="/hajj-and-umrah-list" component={HajjAndUmrahList}/>
            <Route path="/hajj-and-umrah-images-upload" component={HajjAndUmrahUploadImages}/>
            <Route path="/hajj-and-umrah-excel-upload" component={HajjAndUmrahUploadExcel}/>
            {/*<Route path="/ad-units-list" component={ListAdUnits}/>*/}
            {/*<Route path="/ad-units-create" component={CreateAdUnit}/>*/}
            {/*<Route path="/ad-units-edit" component={CreateAdUnit}/>*/}
            <Route path="/timeline-promo-list" component={ListTimelinePromos}/>
            <Route path="/timeline-promo-create" component={CreateTimelinePromo}/>
            <Route path="/timeline-promo-edit" component={CreateTimelinePromo}/>
            <Route path="/timeline-promo-images-upload" component={TimelinePromoUploadImages}/>
            <Route path="/timeline-promo-excel-upload" component={TimelinePromoUploadExcel}/>
            <Route path="/reports" component={Reports}/>
            <Route path="/precalc" component={Precalc}/>
            <Route path="/update-precalcs-list" component={UpdatePrecalcsList}/>
            <Route path="/test-precalc-api" component={TestPrecalcApi}/>
            <Route path="/clear-precalc-cache" component={ClearPrecalcCache}/>
            <Route path="/list-precalc-file-versions" component={ListPrecalcFileVersions}/>
            <Route path="/update-setting-timestamps" component={UpdateSettingTimestamps}/>
            <Route path="/update-settings-raw-file" component={UpdateRawSettingsFile}/>
            <Route path="/update-settings-key-values" component={UpdateSettingsFileKeyValues}/>
            <Route path="/list-settings-file-versions" component={ListSettingsFileVersions}/>
            <Route path="/test-settings-api" component={TestSettingAPI}/>
            <Route path="/quran-playlists/featured-list" component={QuranPlaylistFeaturedList}/>
            <Route path="/quran-playlists/bulk-upload-featured" component={QuranPlaylistBulkUploadFeatured}/>
            <Route path="/hijri-info" component={Hijri}/>
            <Route path="/hijri-years" component={HijriYears}/>
            <Route path="/hijri-eid-ul-fitr-sightings" component={EidUlFitrSightings}/>
            <Route path="/hijri-ramadan-sightings" component={RamdanSightings}/>
            <Route path="/hijri-list-file-versions" component={ListHijriFileVersions}/>
            <Route path="/mg-send-emails" component={EmailDashboard}/>
            <Route path="/mg-email-tags" component={MGEmailTags}/>
            <Route path="/quran-quotes-list" component={QuranQuotesList}/>
            <Route path="/quran-quotes-excel-upload" component={QuranQuotesUploadExcel}/>
            <Route path="/virtual-tours-images-upload" component={VirtulaToursUploadImages}/>
            <Route path="/virtual-tours-excel-upload" component={VirtulaToursUploadExcel}/>
            <Route path="/premium-sku-manager" component={PremiumSKUManager}/>
            <Route path="/subscription-stats" component={SubscriptionStats}/>
            <Route path="/qalbox-upload-media-items" component={QalBoxUploadMediaExcel}/>
            <Route path="/qalbox-upload-display-categories" component={QalBoxUploadDisplayCategoriesExcel}/>
            <Route path="/qalbox-upload-images" component={QalBoxUploadImages}/>
            <Route path="/qalbox-search-media" component={QalBoxSearchMedia}/>
            <Route path="/qalbox-search-media-v2" component={QalBoxSearchMediaV2}/>
            <Route path="/qalbox-shorts" component={QalboxShorts}/>
            <Route path="/qalbox-vod-metadata" component={QalboxVODMetadata}/>
            <Route path="/qalbox-brands" component={QalboxBrands}/>
            <Route path="/qalbox-ad-units" component={QalboxAVOD}/>
            <Route path="/qalbox-media-access" component={QalboxMediaAccess}/> 
            <Route path="/qalbox-organization" component={QalboxOrganization}/>
            <Route path="/qalbox-panelist" component={QalboxPanelist}/>
            <Route path="/qalbox-events" component={QalboxEvents}/>
            <Route path="/qalbox-media-tags" component={QalBoxMediaTags}/>
            <Route path="/qalbox-genres" component={QalBoxGenres}/>
            <Route path="/qalbox-streamers" component={QalboxStreamers}/>
            <Route path="/qalbox-display-categories" component={QalBoxDisplayCategories}/>
            <Route path="/general" component={General}/>
            <Route path="/update-daily-deens-content" component={UpdateContent}/>
            <Route path="/update-daily-deens-content-csv" component={UpdateContentCSV}/>
            <Route path="/tools-upload-images" component={ToolsUploadImages}/>
            <Route path="/aideen" component={ClearQueryCollection}/>
            <Route path="/chat/:id" component={() => <Suspense fallback={<div>Loading...</div>}><ChatPage /></Suspense>} />
            <Route path="/chat" exact component={() => <Suspense fallback={<div>Loading...</div>}><ChatTablePage /></Suspense>} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;

// Initialize firebase configs
export const FirebaseInit = () => {

  if(!firebase.apps.length) {
    firebase.initializeApp(firebaseEnvConfigs[WEB_APP_ENV]);
  }
};
