import { useState, useEffect } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useFetchEvents = (refresh) => {
  const [eventsResponse, setEventsResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-event-service/v1/get/all?limit=100&page=1`
        );
        setEventsResponse(response?.data);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching events');
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();

  }, [refresh]);

  return [ eventsResponse, isLoading, error ];
};