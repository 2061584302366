import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { VOD_ENDPOINT } from '../constants';

export const usePullEvents = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const pullEvents = async (handleEventUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.get(
        `${process.env.REACT_APP_PROD_SYNC_URL}${VOD_ENDPOINT}events`
      );

      if (response?.data && response?.data['success']) {
        setSuccess(true);
        handleEventUpdate(response?.data['payload']);
      } else {
        setError("Failed to pull events from VOD Metadata");
      }
    } catch (error) {
      setError("An error occurred while pulling events from VOD metadata");
    } finally {
      setLoading(false);
    }
  };

  return [ pullEvents, loading, error, success ];
};