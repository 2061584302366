import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QalboxVideoPlayer from "./qalboxVideoPlayer";
import AppModal from "../../../../widgets/modal";
import QalboxJSONEditor from "../../brands/helperComponents/QalboxJSONEditor";

const QalboxURLInputBox = ({
  componentName,
  urlObject = {
    src: "",
    type: "",
    fileId: "",
    configs: null,
    encoder: "",
    mimeType: "",
  },
  onChange,
  isHls = false,
}) => {
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [authXml, setAuthXml] = useState("");
  const [authLoading, setAuthLoading] = useState(true);

  // Fetch Auth XML
  const getAuthXml = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MP_CF_API_BASE_URL}QalBox-GetAuthXml`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: { platform: "web" } }),
        }
      );
      const json = await response.json();
      setAuthXml(json?.result || "");
      setAuthLoading(!json?.result);
    } catch (error) {
      setAuthLoading(true);
    }
  };

  useEffect(() => {
    getAuthXml();
  }, []);

  // Handle text input changes
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    onChange(componentName, { ...urlObject, [name]: value });
  };

  // Handle JSON input change
  const handleJsonInput = (jsObject) => {
    onChange(componentName, { ...urlObject, configs: jsObject });
  };

  return (
    <div>
      <div className="qalbox-form-row">
        <textarea
          value={urlObject?.src || ""}
          name="src"
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder={`${componentName} - src`}
          style={{ marginBottom: "5px", marginRight: "10px" }}
        />
        <CopyToClipboard text={urlObject?.src || ""}>
          <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
            <span
              className="glyphicon glyphicon-duplicate"
              aria-hidden="true"
            />
          </Button>
        </CopyToClipboard>
        {!authLoading && (
          <div
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() => setShowVideoPreview(true)}
          >
            <span className="glyphicon glyphicon-film" aria-hidden="true" />
          </div>
        )}
      </div>
      <div className="qalbox-form-row">
        {["fileId", "encoder", "type", "mimeType"].map((field) => (
          <div className="form-group" key={field} style={{ marginLeft: "5px" }}>
            <label>{`${
              field.charAt(0).toUpperCase() + field.slice(1)
            }:`}</label>
            <input
              name={field}
              value={urlObject?.[field] || ""}
              type="text"
              onChange={handleInputTextChange}
              className="form-control"
              placeholder={`${componentName} - ${field}`}
            />
          </div>
        ))}
      </div>

      <QalboxJSONEditor
        jsonObject={urlObject?.configs || {}}
        previewOff={true}
        onChange={handleJsonInput}
      />

      <AppModal
        show={showVideoPreview}
        showBody={true}
        headingText="Video Preview"
        modalBody={
          <QalboxVideoPlayer
            url={urlObject?.src || ""}
            authXml={authXml}
            encodingMethod={isHls ? "hls" : "dash"}
            configs={urlObject?.configs || {}}
          />
        }
        handleClose={() => setShowVideoPreview(false)}
      />
    </div>
  );
};

export default QalboxURLInputBox;
