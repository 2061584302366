import { useState, useEffect } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useFetchPanelist = (refresh) => {
  const [panelistResponse, setPanelistResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPanelist = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-event-panelist/v1/get/all?limit=100&page=1`
        );
        setPanelistResponse(response?.data);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching media access');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPanelist();

  }, [refresh]);

  return [ panelistResponse, isLoading, error ];
};