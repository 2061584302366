import _ from "lodash";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { getDeletableMediaAccess } from "../helper";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { OrganizationModal } from "./organizationModal";
import { getMediaAccessDisplayData } from "./helpers/organizationDataTransform";
import { getOrganizationColumns } from "./helpers/organizationColumns";
import { useFetchOrganization } from "./hooks/useFetchOrganization";
import { useDeleteOrganization } from "./hooks/useDeleteOrganization";
import { getDeletableOrg } from "./helpers/deletableOrg";

const QalboxOrganization = () => {
  const [refresh, setRefresh] = useState(false);
  const [ShowMediaAccessModal, setShowMediaAccessModal] = useState(false);
  const [organizationRow, setOrganizationRow] = useState(null);
  const [mediaAccesssResponse, isLoadingMediaAccess, errorFetching] = useFetchOrganization(refresh);
  const [deleteOrganization, isDeleting] = useDeleteOrganization();

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} organization items ?`}
            modalBody={getDeletableOrg(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.id.props.text");
              _.forEach(uuidListToDelete, id => {
                deleteOrganization(id, handleRefresh);
              });

              alert('Successfully Deleted !!!');
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const id = _.get(selectedFlatRows, '[0].values.id.props.text', null);
    const shortMediaItem = mediaAccesssResponse.find(item => item.id === id);
    setOrganizationRow(shortMediaItem);
    setShowMediaAccessModal(true);
    resetSelectedRows();
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const onUpdate = (response) => {
    setShowMediaAccessModal(false);
    handleRefresh();
  };

  const handleCreate = () => {
    setOrganizationRow(null);
    setShowMediaAccessModal(true);
  };

  return (
    <LoadingOverlay
      active={isLoadingMediaAccess || isDeleting}
      spinner={<ScaleLoader />}
      text={isDeleting ? "Deleting the selected organization items." : "Loading Organization..."}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Organization"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getMediaAccessDisplayData(mediaAccesssResponse),
            columns: getOrganizationColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditClicked,
            isLoading: isLoadingMediaAccess,
            tableError: errorFetching,
          }}
        />
      </BasicLayout>
      <AppModal
        show={ShowMediaAccessModal}
        showBody={true}
        headingText={`Create Organization`}
        modalBody={
          <OrganizationModal
            data={organizationRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowMediaAccessModal(false); setOrganizationRow(null)}}
      />
    </LoadingOverlay>
  );
};

export default QalboxOrganization;
