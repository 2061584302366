import _ from "lodash";
import React, { lazy, Suspense } from "react";
import { confirmAlert } from "react-confirm-alert";
const AppModal = lazy(() => import("../../../widgets/modal"));

export class VodMetadataHelper {
  static transformApiResponse = (items) => {
    if (_.isEmpty(items)) {
      return [];
    }

    return _.map(items, (item) => {
      const uuid = _.get(item, "uuid");
      const audio = _.get(item, "audio");
      const file = _.get(item, "file");
      const subtitles = _.get(item, "subtitles");

      return {
        title: _.get(item, "title"),
        uuid: uuid,
        audio: JSON.stringify(audio),
        file: JSON.stringify(file),
        subtitles: JSON.stringify(subtitles),
      };
    });
  };

  static getColumnList = () => {
    return React.useMemo(
      () => [
        {
          Header: "UUID",
          accessor: "uuid",
          defaultCanSort: true,
          // defaultCanFilter: true,
          // Filter: CustomFilter,
          width: 100,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
        {
          Header: "Title",
          accessor: "title",
          defaultCanSort: true,
          // defaultCanFilter: true,
          // Filter: CustomFilter,
          width: 200,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
        {
          Header: "File",
          accessor: "file",
          defaultCanSort: true,
          // defaultCanFilter: true,
          // Filter: CustomFilter,
          width: 100,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
        {
          Header: "Audio",
          accessor: "audio",
          defaultCanSort: true,
          defaultCanFilter: false,
          width: 100,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
        {
          Header: "Subtitle ID List",
          accessor: "subtitles",
          defaultCanSort: true,
          defaultCanFilter: false,
          width: 100,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
      ],
      []
    );
  };

  static triggerConfirmAlert = (heading, body, onSuccess) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <AppModal
              show={true}
              showBody={true}
              headingText={heading}
              okButtonClassName="btn-success"
              modalClassName="app-modal-confirm"
              modalBody={body}
              modalBodyClassName={""}
              closeButtonText={"No"}
              okButtonText={"Yes"}
              handleClose={onClose}
              handleOk={() => {
                onSuccess();
                onClose();
              }}
            />
          </Suspense>
        );
      },
    });
  }
}
