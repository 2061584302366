import LoadingOverlay from "react-loading-overlay";
import { useTakeSnapshot } from "../hooks/useTakeSnapshot";
import { ThumbnailComponent } from "./thumbnailComponent";
import { ScaleLoader } from "react-spinners";

export function TencentSnapshotComponent({
  vodMetadata,
  onScreenshot,
  handleInputTextChange,
}) {
  const [takeSnapshot, takingSnapshot] = useTakeSnapshot();

  const handleScreenshot = () => {
    takeSnapshot(vodMetadata?.uuid, vodMetadata?.snapshotTimeOffset, onScreenshot);
  };

  return (
    <LoadingOverlay
      active={takingSnapshot}
      spinner={<ScaleLoader />}
      text="Loading..."
    >
      <div className="form-group">
        <label>Screenshot</label>
        <div className="qalbox-div-row">
          <input
            name="snapshotTimeOffset"
            value={vodMetadata?.snapshotTimeOffset}
            type="text"
            className="form-control small-input-100"
            placeholder="timeoffset"
            onChange={handleInputTextChange}
          />
          <button
            onClick={handleScreenshot}
            className="form-control margin-right-5 small-input-50"
          >
            <i className="fas fa-camera" aria-hidden="true"></i>
          </button>
          <ThumbnailComponent value={vodMetadata?.thumbnailImage} />
        </div>
      </div>
    </LoadingOverlay>
  );
}
