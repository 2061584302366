import _ from "lodash";
import { CopyItem } from "../../common";
import moment from "moment";

export const getEventDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");
    const timestamp = _.get(item, "event_start_timestamp")

    return {
      title: _.get(item, "title"),
      description: _.get(item, "description"),
      panelist_card_title: _.get(item, "panelist_card_title"),
      event_start_timestamp: moment(timestamp).format('MMM DD, YYYY hh:mm A'),
      id: <CopyItem text={id} />,
    };
  });
};
