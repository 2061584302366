import React, { useEffect, useState } from "react";
import { getUTCDateTime } from "../../helper";

export const QalboxDateTimeInput = ({ name, label, value, onChange }) => {
  const [currDateTime, setCurrDateTime] = useState('');
  const [isManual, setIsManual] = useState(false);

  const onDateChange = (e) => {
    let { name, value } = e.target;
    setCurrDateTime(value);
    setIsManual(true);
    onChange({target: {name: name, value: getUTCDateTime(value)}})
  }

  useEffect(() => {
    if (!isManual) {
      setCurrDateTime(value);
    }
  }, value);

  return (
    <div className="form-group">
      <label>{label}</label>
      <input
        name={name}
        value={currDateTime}
        onChange={onDateChange}
        type="datetime-local"
        className="form-control"
      />
    </div>
  );
};
