import React, { useRef, useEffect } from "react";
import axios from 'axios';

export const base64DecodeUint8Array = (base64) => {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.codePointAt(i)
  }
  return bytes.buffer
}

export const base64EncodeUint8Array = (u8Arr) => {
  const CHUNK_SIZE = 0x8000 //arbitrary number
  let index = 0
  const length = u8Arr.length
  let result = ''
  let slice
  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length))
    result += String.fromCharCode.apply(null, slice)
    index += CHUNK_SIZE
  }
  return btoa(result)
}

export const arrayToString = function (a) {
  const b = new Uint16Array(a.buffer)
  return String.fromCharCode.apply(null, b)
}

function QalboxVideoPlayer({ url, encodingMethod = "dash", authXml, configs = null }) {
  const videoPlayerRef = useRef(null);
  const playerRef = React.useRef(null);

  useEffect(() => {
    const options = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
    };

    let player = null;

    if (!playerRef.current) {
      // Initialize the video.js player
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoPlayerRef.current.appendChild(videoElement);

      player = playerRef.current = window.videojs(videoElement, options, () => {
        window.videojs.log("player is ready");
        handlePlayerReady && handlePlayerReady(player);
      });

      // Init eme plugin to work with DRM. Set header common for all license-related requests.

      if (encodingMethod === 'hls') {
        player.eme({});
      } else {
        player.eme({
          emeHeaders: {
            customdata: authXml,
          },
        });
      }
    } else {
      player = playerRef.current;
    }

    const fairplayHlsSrc = {
      src: url,
      type: 'application/x-mpegURL',
      keySystems: configs && configs?.keySystems ? {
        'com.apple.fps.1_0': {
          certificateUri: configs?.keySystems['com.apple.fps.1_0'] && configs.keySystems['com.apple.fps.1_0'].certificateUri ? `${configs.keySystems['com.apple.fps.1_0'].certificateUri}?updateTime=1731469279`: 'https://fp-keyos.licensekeyserver.com/cert/07fbcb8b4cdad9d3c864b4a08585fcc5.der',
          getContentId: function (emeOptions, initData) {
            const contentId = arrayToString(initData)
            if (contentId.includes('skd://')) {
              return contentId.split('skd://')[1].slice(0, 32)
             
            } else throw 'Invalid contentId ' + contentId
          },
          getLicense: function (emeOptions, contentId, keyMessage, callback) {
            const data = 'spc=' + base64EncodeUint8Array(keyMessage) + '&assetId=' + contentId
            axios({
              url: configs?.keySystems['com.apple.fps.1_0'] && configs.keySystems['com.apple.fps.1_0'].licenceUrl ? configs.keySystems['com.apple.fps.1_0'].licenceUrl : 'https://fp-keyos.licensekeyserver.com/getkey',
              method: 'POST',
              data: data,
              headers: {
                customdata: authXml,
              },
            })
              .then(function (result) {
                callback(null, base64DecodeUint8Array(result.data))
              })
              .catch(function () {
                // TODO LOG ERROR
              })
          },
        },
      } : null,
    };

    const wvprDashSrc = {
      src: url, // Replace with your video URL
      type: "application/dash+xml",
      keySystems: configs && configs?.keySystems ? {
        "com.widevine.alpha": configs?.keySystems['com.widevine.alpha'] && configs.keySystems['com.widevine.alpha'].certificateUri ? configs.keySystems['com.widevine.alpha'].certificateUri : "https://wv-keyos.licensekeyserver.com/",
        "com.microsoft.playready":
          "https://pr-keyos.licensekeyserver.com/core/rightsmanager.asmx",
      } : null,
    };

    if (encodingMethod === 'hls') {
      player.src(fairplayHlsSrc);
    } else {
      player.src(wvprDashSrc);
    }

    // Set the source to the player to init/start the playback.

    return () => {
      player.dispose();
    };
  }, [encodingMethod, url]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <div className="container">
      <div data-vjs-player>
        <div ref={videoPlayerRef} />
      </div>
    </div>
  );
}

export default QalboxVideoPlayer;
