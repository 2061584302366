import _ from "lodash";
import { CopyItem } from "../../common";

export const getMediaAccessDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");
    const logo = _.get(item, "logo")

    return {
      name: _.get(item, "name"),
      logo: <img src={logo} alt={"ogo"} width={64} className="align-self-center" />,
      description: _.get(item, "description"),
      id: <CopyItem text={id} />,
    };
  });
};
