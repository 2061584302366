import React from "react";
import CustomFilter from "../../../../widgets/qalbox-table/custom-filter";

export const getQalboxMediaAccessColumns = () => {
  return React.useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Name",
      accessor: "sku_name",
      defaultCanSort: true,
      defaultCanFilter: true,
      Filter: CustomFilter,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Description",
      accessor: "sku_description",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Android SKU",
      accessor: "android_sku",
      defaultCanSort: true,
      defaultCanFilter: false,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "IOS SKU",
      accessor: "ios_sku",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Web SKU",
      accessor: "web_sku",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    }
  ]);
};
