import { useState } from "react";
import axiosService from "../../../../service_v2/common/axios-service";
import { VOD_ENDPOINT } from "../constants";

export const useProcessModeratorVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const processModeratorVersion = async (uuid, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.post(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${VOD_ENDPOINT}transcode/moderator`,
        {
          uuid,
        }
      );

      if (response?.data && response?.data["success"]) {
        setSuccess(true);
        handleUpdate(true, response?.data["payload"]);
      } else {
        setError(response?.data["error"]);
      }
    } catch (error) {
      setError(error);
      handleUpdate(false, error);
    } finally {
      setLoading(false);
    }
  };

  return [processModeratorVersion, loading, error, success];
};
