import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { EventModal } from "./eventModal";
import { getEventDisplayData } from "./helpers/eventDataTransform";
import { getEventsColumns } from "./helpers/eventColumns";
import { useFetchEvents } from "./hooks/useFetchEvents";
import { useDeleteEvent } from "./hooks/useDeleteEvent";
import { useFetchOrganization } from "../organization/hooks/useFetchOrganization";
import { useFetchPanelist } from "../panelist/hooks/useFetchPanelist";
import { getDeletableEvent } from "./helpers/deletableEvent";

const QalboxEvents = () => {
  const [refresh, setRefresh] = useState(false);
  const [ShowEventModal, setShowEventModal] = useState(false);
  const [eventRow, setEventRow] = useState(null);
  const [eventsResponse, isLoadingEvents, errorFetching] = useFetchEvents(refresh);
  const [deleteEvent, isDeleting] = useDeleteEvent();
  const [organizationResponse, isLoadingOrg] = useFetchOrganization(refresh);
  const [panelistResponse, isLoadingPanelist] = useFetchPanelist(refresh);

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} events items ?`}
            modalBody={getDeletableEvent(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.id.props.text");
              _.forEach(uuidListToDelete, id => {
                deleteEvent(id, handleRefresh);
              });

              alert('Successfully Deleted !!!');
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const id = _.get(selectedFlatRows, '[0].values.id.props.text', null);
    const shortMediaItem = eventsResponse.find(item => item.id === id);
    setEventRow(shortMediaItem);
    setShowEventModal(true);
    resetSelectedRows();
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const onUpdate = (response) => {
    setShowEventModal(false);
    handleRefresh();
  };

  const handleCreate = () => {
    setEventRow(null);
    setShowEventModal(true);
  };

  return (
    <LoadingOverlay
      active={isLoadingEvents || isDeleting || isLoadingOrg || isLoadingPanelist}
      spinner={<ScaleLoader />}
      text={isDeleting ? "Deleting the selected Event items." : "Loading Events..."}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Events"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getEventDisplayData(eventsResponse),
            columns: getEventsColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditClicked,
            isLoading: isLoadingEvents || isLoadingOrg || isLoadingPanelist,
            tableError: errorFetching,
          }}
        />
      </BasicLayout>
      <AppModal
        show={ShowEventModal}
        showBody={true}
        headingText={`Create Event`}
        modalBody={
          <EventModal
            data={eventRow}
            panelists={panelistResponse ?? []}
            organizations={organizationResponse ?? []}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowEventModal(false); setEventRow(null)}}
      />
    </LoadingOverlay>
  );
};

export default QalboxEvents;
