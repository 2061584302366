import React from "react";
import QalboxURLInputBox from "./qalboxURLInputBox";

const QalboxURLInputV1 = ({ componentName, urlArray = [{}, {}], onChange }) => {
  // Handles updates to the URL array
  const handleChange = (index, updatedUrlObject) => {
    const newUrlArray = [...urlArray];
    newUrlArray[index] = updatedUrlObject;
    onChange(componentName, newUrlArray);
  };

  return (
    <div className="form-group">
      <label>HLS URL</label>
      <QalboxURLInputBox
        componentName={`${componentName}.0`}
        urlObject={urlArray[0] || {}}
        onChange={(name, value) => handleChange(0, value)}
        isHls={true}
      />
      <label>DASH URL</label>
      <QalboxURLInputBox
        componentName={`${componentName}.1`}
        urlObject={urlArray[1] || {}}
        onChange={(name, value) => handleChange(1, value)}
      />
    </div>
  );
};

export default QalboxURLInputV1;
