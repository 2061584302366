export const getUploadFormMissingFields = (uploadObject, mediaType) => {
  const missingFields = [];
  if (!uploadObject.media_file_url) {
    missingFields.push("media_file_url");
  }
  if (!uploadObject.title) {
    missingFields.push("title");
  }
  if (!uploadObject.orientation) {
    missingFields.push("orientation");
  }
  if (!uploadObject.drm) {
    missingFields.push("drm");
  }
  if (!uploadObject.media_uuid) {
    missingFields.push("media_uuid");
  }
  if (mediaType !== 'media' && mediaType !== 'shorts' && mediaType !== 'preview') {
    missingFields.push("correct media uuid need to extract media type");
  }

  return missingFields;
};
