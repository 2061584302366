import React, { useEffect, useState } from "react";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import { useCreatePanelist } from "./hooks/useCreatePanelist";
import { useUpdatePanelist } from "./hooks/useUpdatePanelist";
import QalboxImageUploader from "../media/helperComponents/qalboxImageUploader";

const initialOrgObject = {
  name: "",
  title: "",
  image: "",
  company_name: ""
};

export const PanelistModal = ({ data, handleUpdates }) => {
  const [panelist, setPanelist] = useState(initialOrgObject);
  const [createPanelistHook] = useCreatePanelist();
  const [updatePanelistHook] = useUpdatePanelist();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setPanelist((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSave = () => {
    createPanelistHook(panelist, handleUpdates);
  };

  const handleOnUpdate = async () => {
    updatePanelistHook(panelist, handleUpdates);
  };

  useEffect(() => {
    if (data) {
      setPanelist(data);
    }
  }, [data]);

  return (
    <div>
      <QalboxTextInput
        label="Name"
        name="name"
        value={panelist.name}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Title"
        name="title"
        value={panelist.title}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Comapny Name"
        name="company_name"
        value={panelist.company_name}
        onChange={handleInputTextChange}
      />
      <label>Panelist Image</label>
      <QalboxImageUploader
        name={"image"}
        value={panelist.image}
        handleInputTextChange={handleInputTextChange}
      />
      <br></br>
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};