import { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
const AppModal = lazy(() => import("../../../widgets/modal"));

export class QalBoxDisplayUtil {
  static modalContainer = document.createElement("div");
  static modalStack = [];

  // Ensure a modal container is appended to the DOM
  static ensureModalContainer() {
    if (!document.body.contains(this.modalContainer)) {
      this.modalContainer.id = "qalbox-modal-container";
      document.body.appendChild(this.modalContainer);
    }
  }

  static closeModal(modalId) {
    // Remove the modal from the stack
    this.modalStack = this.modalStack.filter((modal) => modal.id !== modalId);

    // Update the modal stack rendering
    this.renderModals();
  }

  static renderModals() {
    this.ensureModalContainer();

    // Render all active modals in the stack
    ReactDOM.render(
      <>
        {this.modalStack.map(({ id, modal }) => (
          <Suspense key={id} fallback={<div>Loading...</div>}>
            {modal}
          </Suspense>
        ))}
      </>,
      this.modalContainer
    );
  }

  static triggerModal({
    heading,
    body,
    onSuccess,
    closeButtonText = "Close",
    okButtonText = "OK",
    okButtonClassName = "",
    modalClassName = "",
  }) {
    const modalId = `modal-${this.modalStack.length + 1}`;
    const handleClose = () => this.closeModal(modalId);

    const modal = (
      <AppModal
        show={true}
        showBody={true}
        headingText={heading}
        modalBody={body}
        modalBodyClassName=""
        okButtonClassName={okButtonClassName}
        modalClassName={`app-modal ${modalClassName}`}
        closeButtonText={closeButtonText}
        okButtonText={okButtonText}
        handleClose={handleClose}
        handleOk={() => {
          onSuccess?.();
          handleClose();
        }}
        zIndex={1000 + this.modalStack.length * 10} // Dynamic z-index for stacking
      />
    );

    // Add the modal to the stack and render
    this.modalStack.push({ id: modalId, modal });
    this.renderModals();
  }

  static triggerConfirmAlert(heading, body, onSuccess, closeButtonText = "No", okButtonText = "Yes") {
    this.triggerModal({
      heading,
      body,
      onSuccess,
      closeButtonText,
      okButtonText,
      okButtonClassName: "btn-success",
      modalClassName: "app-modal-confirm",
    });
  }

  static triggerAppModal(heading, body, onSuccess) {
    this.triggerModal({
      heading,
      body,
      onSuccess,
    });
  }
}
