import _ from "lodash";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import { useUploadMediaFile } from "../hooks/useUploadMediaFile";
import QalboxSelectDropdown from "../../media/helperComponents/qalboxSelect";
import { convertArrayToSelectList, convertToSelectObject } from "../../helper";
import { getUploadFormMissingFields } from "./helper";
import { SONIC_BRANDING } from "../constants";

export default function VodMediaFileUploadModal({ vodMetadata, onUpload }) {
  const [uploadObject, setUploadObject] = useState({
    media_file_url: "",
    title: "",
    orientation: "landscape",
    drm: true,
    media_uuid: "",
    branding: "",
  });
  const [formError, setFormError] = useState({ isError: false, message: "" });

  const [triggerUploadMedia, isUploadMediaLoading, isUploadMediaError] =
    useUploadMediaFile();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setUploadObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelect = (name, option) => {
    setUploadObject((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setUploadObject((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleUploadMedia = () => {
    const mediaType = uploadObject.media_uuid ? uploadObject.media_uuid.split('_')[0] : '';
    const missingFields = getUploadFormMissingFields(uploadObject, mediaType);
    
    if (missingFields && missingFields.length > 0) {
      setFormError({
        isError: true,
        message: `Missing following fields ${missingFields}`,
      });
      return;
    }

    triggerUploadMedia(uploadObject, onUpload);
  };

  useEffect(() => {
    if (vodMetadata) {
      setUploadObject({
        title: vodMetadata.title,
        orientation: vodMetadata.orientation,
        drm: vodMetadata.drm,
        media_uuid: vodMetadata.uuid,
        branding: vodMetadata.branding,
      });
    }
  }, [vodMetadata]);

  return (
    <LoadingOverlay
      active={isUploadMediaLoading}
      spinner={<ScaleLoader />}
      text="Loading..."
    >
      <div className="form-group">
        <label>Media File URL</label>
        <input
          value={uploadObject.media_file_url}
          name="media_file_url"
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder="Media file URL"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <div className="form-group">
        <label>Title</label>
        <input
          value={uploadObject.title}
          name="title"
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder="Destination Path"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <div className="form-group">
        <label>UUID</label>
        <input
          value={uploadObject.media_uuid}
          name="media_uuid"
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder="Destination Path"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <div className="form-group" style={{ width: "100px" }}>
        <label>DRM</label>
        <input
          type="checkbox"
          name="drm"
          checked={uploadObject.drm}
          onChange={handleCheckboxChange}
          style={{ transform: "scale(1.5)", marginLeft: "10px" }}
        />
      </div>
      <div className="form-group">
        <label>Orientation</label>
        <QalboxSelectDropdown
          name={"orientation"}
          selectedOption={convertToSelectObject(uploadObject.orientation)}
          options={convertArrayToSelectList(["portrait", "landscape"])}
          onChange={handleSelect}
        />
      </div>
      <div className="form-group">
        <label>Branding</label>
        <QalboxSelectDropdown
          name={"branding"}
          selectedOption={convertToSelectObject(uploadObject.branding)}
          options={convertArrayToSelectList(SONIC_BRANDING)}
          onChange={handleSelect}
        />
      </div>
      <div className="form-group">
        <button onClick={handleUploadMedia} className="pagination-button">
          Upload Media
        </button>
      </div>
      <div style={{ color: "red" }}>
        {formError.isError ? formError.message : null}
      </div>
    </LoadingOverlay>
  );
}
