import React from "react";
import CustomFilter from "../../../../widgets/qalbox-table/custom-filter";

export const getPanelistColumns = () => {
  return React.useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      width: 300,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Name",
      accessor: "name",
      defaultCanSort: true,
      defaultCanFilter: true,
      Filter: CustomFilter,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Title",
      accessor: "title",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Company Name",
      accessor: "company_name",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Image",
      accessor: "image",
      defaultCanSort: true,
      defaultCanFilter: false,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    }
  ]);
};
