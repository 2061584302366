export default function TencentTranscodeTaskComponent({ tasks }) {
  return (
    <>
      {tasks &&
        tasks.map((task, index) => {
          return (
            <div key={index}>
              <div className="qalbox-form-row">
                <input
                  value={task.taskId}
                  type="text"
                  disabled
                  className="form-control small-input-taskid"
                  placeholder="Audio ID"
                  style={{ marginBottom: "5px" }}
                />
                <input
                  value={task.status}
                  type="text"
                  disabled
                  className="form-control small-input-status input-row-margin"
                  placeholder="Audio Language"
                  style={{ marginBottom: "5px" }}
                />
              </div>
            </div>
          );
        })}
    </>
  );
}
