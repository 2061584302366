import { useState, useEffect } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { VOD_ENDPOINT } from '../constants';

export const useFetchVodMetadata = (refresh) => {
  const [vodMetadataResponse, setVodMetadataResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVodMetadata = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${VOD_ENDPOINT}metadata`
        );

        if (response?.data && response?.data['success']) {
          const vodMetadata = response?.data['payload'];
          setVodMetadataResponse(vodMetadata);
        } else {
          throw new Error('Fetching VOD metadata Response has issues !!!');
        }
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching VOD metadata');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVodMetadata();

  }, [ refresh ]);

  return [ vodMetadataResponse, isLoading, error ];
};