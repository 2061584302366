import _ from "lodash";
import { useState, lazy, Suspense } from "react";
import { toast } from "react-toastify";
import { useDeleteSubtitile } from "../hooks/useDeleteSubtitle";
import { QalBoxDisplayUtil } from "../../utils/qalboxModalUtil";
const AppModal = lazy(() => import("../../../../widgets/modal"));
const VodSubtitleFileUploadModal = lazy(() =>
  import("../modals/vodSubtitleUploadModal")
);

export const TencentSubtitleComponent = ({
  vodMetadata,
  handleSubtitleChange,
}) => {
  const [showSubtitleUploadModal, setShowSubtitleUploadModal] = useState(false);
  const [deleteSubtitle, deletingSubtitle] = useDeleteSubtitile();

  const handleSubtitleRemove = (id) => {
    if (!id || !vodMetadata.uuid) {
      alert('Not a valid subtitle ID');
      return;
    }

    QalBoxDisplayUtil.triggerConfirmAlert(
      `Are you sure, you want to delete the subtitle ID ${id}`,
      <></>,
      () => deleteSubtitle(vodMetadata.uuid, id, handleSubtitleChange)
    );
  };

  const handleInputTextChange = () => {};

  const handleSubtitleFileUpload = (isSuccess, response) => {
    if (isSuccess) {
      toast.success(
        `Subtitle file uploding successful !!! ${JSON.stringify(
          response.AddedSubtitleSet
        )}`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      setShowSubtitleUploadModal(false);
    } else {
      toast.error("Subtitle file uploding failed !!! " + response, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    handleSubtitleChange();
  };

  return (
    <div>
      <div className="form-group">
        <label>Subtitles</label>
        {vodMetadata.subtitles &&
          vodMetadata.subtitles.map((subtitleItem, index) => {
            return (
              <div key={index}>
                <div className="qalbox-form-row">
                  <div
                    style={{ marginRight: "10px" }}
                    onClick={() => handleSubtitleRemove(subtitleItem.id)}
                  >
                    <span
                      className="glyphicon glyphicon-remove"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    value={subtitleItem.id}
                    name={`subtitle.id-${index}`}
                    type="text"
                    disabled
                    onChange={(e) => handleInputTextChange(e, index)}
                    className="form-control small-input-100"
                    placeholder="Subtitle ID"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={subtitleItem.language}
                    name={`subtitle.language-${index}`}
                    type="text"
                    disabled
                    onChange={(e) => handleInputTextChange(e, index)}
                    className="form-control small-input-100 input-row-margin"
                    placeholder="Subtitle Language"
                    style={{ marginBottom: "5px" }}
                  />
                  <input
                    value={subtitleItem.name}
                    name={`subtitle.name-${index}`}
                    type="text"
                    disabled
                    onChange={(e) => handleInputTextChange(e, index)}
                    className="form-control small-input-200 input-row-margin"
                    placeholder="Subtitle Name"
                    style={{ marginBottom: "5px" }}
                  />
                  <a href={subtitleItem.url} className="subtitle-url-button">
                    {subtitleItem.url}
                  </a>
                </div>
              </div>
            );
          })}
        <div onClick={() => setShowSubtitleUploadModal(true)}>
          <span className="glyphicon glyphicon-plus" aria-hidden="true" />
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <AppModal
          show={showSubtitleUploadModal}
          showBody={true}
          headingText={`Upload subtitles for ${vodMetadata?.uuid} - ${vodMetadata?.title}`}
          modalBody={
            <VodSubtitleFileUploadModal
              metadata={vodMetadata}
              onUpload={handleSubtitleFileUpload}
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => {
            setShowSubtitleUploadModal(false);
          }}
        />
      </Suspense>
    </div>
  );
};
