import _ from "lodash";
import React, { lazy, Suspense, useState } from "react";
import "./style.css";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { useFetchVodMetadata } from "./hooks/useFetchVodMetadata";
import {
  TOASTER_AUTO_CLOSE_DURATION,
  VOD_METADATA_RECORD_DELETING_MSG,
} from "./constants";
import { VodMetadataHelper } from "./helper";
import { usePullEvents } from "./hooks/usePullEvents";
import { QalBoxDisplayUtil } from "../utils/qalboxModalUtil";
const AppModal = lazy(() => import("../../../widgets/modal"));
const VodMediaFileUploadModal = lazy(() => import("./modals/mediaFileUploadModal"));
const VodMetadatadModal = lazy(() => import("./modals/vodMetadataModal"));

export const QalboxVODMetadata = () => {
  const [selectedMetadataRow, setSelectedMetadataRow] = useState({
    uuid: "",
    title: "",
    task_id: "",
    file_id: "",
    subtitle_ids: "",
  });
  const [
    vodMetadataResponse,
    isVodMetadataResponseLoading,
    isVodMetadataResponseError,
  ] = useFetchVodMetadata(false);
  const [pullEvents, isPullEventApiLoading, isPullEventApiError] =
    usePullEvents();

  const handleUpload = () => {
    QalBoxDisplayUtil.triggerAppModal(
      `Upload Media File`,
      <VodMediaFileUploadModal onUpload={handleMediaUpload} />,
    );
  };

  const pullVodEvents = () => {
    pullEvents();
  };

  const onEdit = (selectedFlatRows, resetSelectedRows) => {
    const editableRow = selectedFlatRows[0]?.values;
    const uuid = editableRow?.uuid;

    if (!uuid) return;

    const editableObject = vodMetadataResponse.find(
      (metadata) => metadata.uuid === uuid
    );

    setSelectedMetadataRow(editableObject);

    QalBoxDisplayUtil.triggerAppModal(
      `Metadata for ${editableObject.uuid} - ${editableObject.title}`,
      <VodMetadatadModal uuid={editableObject?.uuid} handleTranscodeComplete={handleTranscodeComplete} />,
    );

    resetSelectedRows();
  };

  const handleMediaUpload = (isSuccess, response) => {
    if (isSuccess) {
      toast.success("Media uploding successful !!! " + response.TaskId, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error("Media uploding failed !!! " + response, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const handleTranscodeComplete= (isSuccess, response) => {
    if (isSuccess) {
      toast.success("Media transcoding successful !!! ", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error("Media transcoding failed !!! " + response, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <LoadingOverlay
      active={isPullEventApiLoading}
      spinner={<ScaleLoader />}
      text={VOD_METADATA_RECORD_DELETING_MSG}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={TOASTER_AUTO_CLOSE_DURATION} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">QalBox VOD Metadata</h3>
          </Col>
          <Col md={3} className="col-md-3-padding-top">
            <button className="create-button" onClick={handleUpload}>
              Upload
            </button>
            <button
              className="refresh-button margin-left-5"
              onClick={pullVodEvents}
            >
              Refresh
            </button>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: VodMetadataHelper.transformApiResponse(vodMetadataResponse),
            columns: VodMetadataHelper.getColumnList(),
            handleDelete: null,
            handleEdit: onEdit,
            isLoading: isVodMetadataResponseLoading,
            tableError: isVodMetadataResponseError,
          }}
        />
      </BasicLayout>
    </LoadingOverlay>
  );
};
