import _ from "lodash";
import React, { useEffect, useState } from "react";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import { useCreateMediaAccess } from "./hooks/useCreateMediaAccess";
import { useUpdateMediaAccess } from "./hooks/useUpdateMediaAccess";

const initialMediaAccessbject = {
  name: "",
  sku_name: "",
  sku_description: "",
  android_sku: "",
  ios_sku: "",
  web_sku: "",
  active: "true",
};

export const MediaAccessModal = ({ data, handleUpdates }) => {
  const [mediaAccess, setMediaAccess] = useState(initialMediaAccessbject);
  const [createMediaAccessHook] = useCreateMediaAccess();
  const [updateMediaAccessHook] = useUpdateMediaAccess();

  const handleSelect = (name, option) => {
    setMediaAccess((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setMediaAccess((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setMediaAccess((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleOnSave = () => {
    createMediaAccessHook(mediaAccess, handleUpdates);
  };

  const handleOnUpdate = async () => {
    updateMediaAccessHook(mediaAccess, handleUpdates);
  };

  useEffect(() => {
    if (data) {
      setMediaAccess(data);
    }
  }, [data]);

  return (
    <div>
      <QalboxTextInput
        label="SKU Name"
        name="sku_name"
        value={mediaAccess.sku_name}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="SKU Description"
        name="sku_description"
        value={mediaAccess.sku_description}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Android SKU"
        name="android_sku"
        value={mediaAccess.android_sku}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="IOS SKU"
        name="ios_sku"
        value={mediaAccess.ios_sku}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Web SKU"
        name="web_sku"
        value={mediaAccess.web_sku}
        onChange={handleInputTextChange}
      />
      <QalboxInputWrapper
        label="Is Active"
        component={
          <input
            type="checkbox"
            name="active"
            checked={mediaAccess.active}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        }
      />
      <br></br>
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};