import _ from "lodash";
import { CopyItem } from "../../common";

export const getMediaAccessDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    return {
      id: <CopyItem text={_.get(item, "id", "")} />,
      sku_name: _.get(item, "sku_name", ""),
      sku_description: _.get(item, "sku_description", ""),
      ios_sku: _.get(item, "ios_sku", ""),
      android_sku: _.get(item, "android_sku", ""),
      web_sku: _.get(item, "web_sku", "")
    };
  });
};
